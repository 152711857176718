import React from 'react';
// import './header.css';
import logo from '../../assets/ssezibwalogo.png';
import logo_mini from '../../assets/favicon.ico';
import { Link } from 'react-router-dom';
// import CSS from 'csstype'
// import MobileNav from '../MobileNav/mobileNav'
// import { Link } from 'react-router-dom';


// const style: CSS.Properties = {
const style = {
    width: '0',
    height: '100%',
    position: 'fixed',
    zIndex: 1,
    left: 0,
    top: 0,
    backgroundColor: '#31494b',
    overflowX: 'hidden',
    transition: "all 0.5s ease 0s",
}

class NavBar extends React.Component {

    // showNav: boolean = false;
    state = {
        style,
        hideNav: true
    }
    // myRef = React.createRef();

    // openNavBar = () => {
    //     this.showNav = !this.showNav;
    //     this.setState({
    //         style: {
    //             width: this.showNav ? '100%' : '0',
    //             height: '100%',
    //             position: 'fixed',
    //             zIindex: 1,
    //             left: 0,
    //             top: 0,
    //             backgroundColor: '#31494b',
    //             overflowX: 'hidden',
    //             transition: "all 0.5s ease 0s",
    //         },
    //         showNav: true
    //     })
    // }

    // componentDidMount() {
    //     window.addEventListener('scroll', this.handleScroll);
    // }

    // handleScroll = (event) => {
    //     let scrollPosition = window.pageYOffset;
    //     if (scrollPosition > 775) {
    //         this.setState({ hideNav: false });
    //     } else {
    //         this.setState({ hideNav: true });
    //     }
    // }

    onLogout = () => {
        // const cookies = new Cookies();
        // cookies.remove('parking@user');
        // window.location.replace('/login')

        localStorage.removeItem('ssezibwa@user');
        window.location.replace('/');
    }


    render() {
        return (<>
            <nav className="navbar col-lg-12 col-12 p-0 fixed-top d-flex flex-row">
                <div className="text-center navbar-brand-wrapper d-flex align-items-center justify-content-center">
                    <a className="navbar-brand brand-logo" href="index.html"><img src={logo} alt="logo" /></a>
                    <a className="navbar-brand brand-logo-mini" href="index.html"><img src={logo_mini} alt="logo" /></a>
                </div>
                <div className="navbar-menu-wrapper d-flex align-items-center justify-content-end">
                    <button className="navbar-toggler navbar-toggler align-self-center" type="button" data-toggle="minimize">
                        <span className="icon-menu"></span>
                    </button>
                    <ul className="navbar-nav mr-lg-2">
                        <li className="nav-item nav-search d-none d-lg-block">
                            <div className="input-group">
                                <div className="input-group-prepend">
                                    <span className="input-group-text" id="search">
                                        <i className="icon-search"></i>
                                    </span>
                                </div>
                                <input type="text" className="form-control" placeholder="Search Projects.." aria-label="search"
                                    aria-describedby="search" />
                            </div>
                        </li>
                    </ul>
                    <ul className="navbar-nav navbar-nav-right">
                        <li className="nav-item dropdown d-lg-flex d-none">
                            <button type="button" className="btn btn-info font-weight-bold">+ Create New</button>
                        </li>
                        <li className="nav-item dropdown d-flex">
                            <a className="nav-link count-indicator dropdown-toggle d-flex justify-content-center align-items-center"
                                id="messageDropdown" href="#" data-toggle="dropdown">
                                <i className="icon-air-play mx-0"></i>
                            </a>
                            <div className="dropdown-menu dropdown-menu-right navbar-dropdown preview-list" aria-labelledby="messageDropdown">
                                <p className="mb-0 font-weight-normal float-left dropdown-header">Messages</p>
                                <a className="dropdown-item preview-item">
                                    <div className="preview-thumbnail">
                                        <img src="images/faces/face4.jpg" alt="image" className="profile-pic" />
                                    </div>
                                    <div className="preview-item-content flex-grow">
                                        <h6 className="preview-subject ellipsis font-weight-normal">David Grey
                                        </h6>
                                        <p className="font-weight-light small-text text-muted mb-0">
                                            The meeting is cancelled
                                        </p>
                                    </div>
                                </a>
                                <a className="dropdown-item preview-item">
                                    <div className="preview-thumbnail">
                                        <img src="images/faces/face2.jpg" alt="image" className="profile-pic" />
                                    </div>
                                    <div className="preview-item-content flex-grow">
                                        <h6 className="preview-subject ellipsis font-weight-normal">Tim Cook
                                        </h6>
                                        <p className="font-weight-light small-text text-muted mb-0">
                                            New product launch
                                        </p>
                                    </div>
                                </a>
                                <a className="dropdown-item preview-item">
                                    <div className="preview-thumbnail">
                                        <img src="images/faces/face3.jpg" alt="image" className="profile-pic" />
                                    </div>
                                    <div className="preview-item-content flex-grow">
                                        <h6 className="preview-subject ellipsis font-weight-normal"> Johnson
                                        </h6>
                                        <p className="font-weight-light small-text text-muted mb-0">
                                            Upcoming board meeting
                                        </p>
                                    </div>
                                </a>
                            </div>
                        </li>
                        <li className="nav-item dropdown d-flex mr-4 ">
                            <a className="nav-link count-indicator dropdown-toggle d-flex align-items-center justify-content-center"
                                id="notificationDropdown" href="#" data-toggle="dropdown">
                                <i className="icon-cog"></i>
                            </a>
                            <div className="dropdown-menu dropdown-menu-right navbar-dropdown preview-list"
                                aria-labelledby="notificationDropdown">
                                <p className="mb-0 font-weight-normal float-left dropdown-header">Settings</p>
                                <a className="dropdown-item preview-item">
                                    <i className="icon-head"></i> Profile
                                </a>
                                {/* <a className="dropdown-item preview-item">
                                    <i className="icon-inbox"></i> Logout
                                </a> */}
                                <Link className="dropdown-item preview-item" to="#" onClick={this.onLogout}>
                                    <i className="icon-inbox"></i> Sign Out
                                </Link>
                            </div>
                        </li>
                        <li className="nav-item dropdown mr-4 d-lg-flex d-none">
                            <a className="nav-link count-indicatord-flex align-item s-center justify-content-center" href="#">
                                <i className="icon-grid"></i>
                            </a>
                        </li>
                    </ul>
                    <button className="navbar-toggler navbar-toggler-right d-lg-none align-self-center" type="button"
                        data-toggle="offcanvas">
                        <span className="icon-menu"></span>
                    </button>
                </div>
            </nav>





            {/* // <div className={this.state.hideNav ? 'hide header' : ' header show'}>
            //     <div className={this.state.hideNav ? 'cover cover-show' : 'cover cover-hide'}></div>
            //     <div className='nav'>
            //         <Link to="/"><img className="logo" src={logo} alt="logo" /></Link>

            //         <div className='menu' onClick={this.openNavBar}>
            //             <div className='bar half start'></div>
            //             <div className='bar'></div>
            //             <div className='bar half end'></div>
            //         </div>
            //     </div>

            //     <MobileNav style={this.state.style}>
            //         <button onClick={this.openNavBar} className='close'>&times;</button>

            //         <div className="overlay__links">
            //             <Link to="/"><img className="logo" src={logo} alt="logo" /></Link>
            //             <Link to="/">Home</Link>
            //             <Link to="/activities">Activities</Link>
            //             <Link to="/notfound">Activities</Link>
            //             <Link to="/book_an_experience">Booking</Link>
            //             <Link to="/facilities">Facilities</Link>
            //             <Link to="/gallery">Gallery</Link>
            //             <Link to="/about">About</Link>
            //         </div>
            //     </MobileNav>

            //     <script></script>
            // </div> */}

        </>
        )
    }
}

export default NavBar;

import React from 'react';
// import { Link } from 'react-router-dom';
// import you from '../../assets/icons/youtube.png'
// import insta from '../../assets/icons/insta.png'
// import face from '../../assets/icons/face.png'
// import associate from '../../assets/images/association.png'
// import asso from '../../assets/images/asso.png'
// import tweet from '../../assets/icons/twitter.png'
// import ioni from '../../assets/icons/email.png'
// import './footer.css'
// import FooterNav from '../MobileNav/footerNav';
// import logo from '../../assets/ssezibwalogo.png';


const Footer = () => {

    return (
        // <footer classNameName="footer">
        //     <div classNameName="d-sm-flex justify-content-center justify-content-sm-between">
        //         <span className="text-muted d-block text-center text-sm-left d-sm-inline-block">@2020 Ssezibwa Falls. All rights reserved <br />Designed by Thrivetech Uganda. © ssezibwafalls.com 2020</span>
        //         {/* <!-- <span className="float-none float-sm-right d-block mt-1 mt-sm-0 text-center"> Free <a href="https://www.bootstrapdash.com/" target="_blank">Bootstrap dashboard templates</a> from Bootstrapdash.com</span> --> */}
        //     </div>
        // </footer>

        <div className="footer">
            <div className="d-sm-flex justify-content-center justify-content-sm-between">
                <span className="text-muted d-block text-center text-sm-left d-sm-inline-block">@2020 Copyright © www.ssezibwa-falls.netlify.app</span>
                <span className="float-none float-sm-right d-block mt-1 mt-sm-0 text-center"> All rights reserved. <a
                    href="https://www.ssezibwa-falls.netlify.app/" target="_blank" rel="noreferrer">Ssezibwa site</a> from
                    thrivetecug.com</span>
            </div>
        </div>
    )
}

export default Footer;

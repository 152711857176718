import React from 'react';
import { Link } from 'react-router-dom';
// import you from '../../assets/icons/youtube.png'
// import insta from '../../assets/icons/insta.png'
// import face from '../../assets/icons/face.png'
// import associate from '../../assets/images/association.png'
// import asso from '../../assets/images/asso.png'
// import tweet from '../../assets/icons/twitter.png'
// import ioni from '../../assets/icons/email.png'
// import './footer.css'
// import FooterNav from '../MobileNav/footerNav';
// import face from '../../assets/images/face13.jpg';
import ajaxUser from '../../utils/remote/ajaxUser';
import functions from '../../utils/functions';


class SideBar extends React.Component {

    state = {
        userId: functions.sessionGuard(),
        username: '',
        photo: '',
        roleName: ''
    }

    componentDidMount() {
        this.getUser();
    }


    getUser = async () => {
        const server_response = await ajaxUser.userInfo(this.state.userId);
        console.log(server_response)
        if (server_response.status === "OK") {
            this.setState({
                username: server_response.details.content.username,
                roleName: server_response.details.content.user_role.role_name,
                photo: server_response.details.content.profile_photo.file_path

            })
        } else {
            this.setState({
                userList: "404"
            })
        }
    }

    render() {

        return (
            <nav className="sidebar sidebar-offcanvas" id="sidebar">
                <div className="user-profile">
                    <div className="user-image">
                        <img src={this.state.photo} alt="profile" />
                    </div>
                    <div className="user-name">
                        {this.state.username}
                    </div>
                    <div className="user-designation">
                        {this.state.roleName}
                    </div>
                </div>
                <ul className="nav">
                    <li className="nav-item">
                        <Link className="nav-link" to="/">
                            <i className="icon-box menu-icon"></i>
                            <span className="menu-title">Dashboard</span>
                        </Link>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link" data-toggle="collapse" href="#ui-basic" aria-expanded="false" aria-controls="ui-basic">
                            <i className="icon-disc menu-icon"></i>
                            <span className="menu-title">Configurations</span>
                            <i className="menu-arrow"></i>
                        </a>
                        <div className="collapse" id="ui-basic">
                            <ul className="nav flex-column sub-menu">
                                <li className="nav-item"> <a className="nav-link" href="pages/ui-features/buttons.html">Customers</a></li>
                                <li className="nav-item"> <a className="nav-link" href="pages/ui-features/typography.html">Bookings</a></li>
                                <li className="nav-item"> <Link className="nav-link" to="/packages">Packages</Link></li>
                            </ul>
                        </div>
                    </li>
                    {/* <li className="nav-item">
                        <a className="nav-link" href="pages/forms/basic_elements.html">
                            <i className="icon-file menu-icon"></i>
                            <span className="menu-title">Form elements</span>
                        </a>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link" href="pages/charts/chartjs.html">
                            <i className="icon-pie-graph menu-icon"></i>
                            <span className="menu-title">Charts</span>
                        </a>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link" href="pages/tables/basic-table.html">
                            <i className="icon-command menu-icon"></i>
                            <span className="menu-title">Tables</span>
                        </a>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link" href="pages/icons/feather-icons.html">
                            <i className="icon-help menu-icon"></i>
                            <span className="menu-title">Icons</span>
                        </a>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link" data-toggle="collapse" href="#auth" aria-expanded="false" aria-controls="auth">
                            <i className="icon-head menu-icon"></i>
                            <span className="menu-title">User Pages</span>
                            <i className="menu-arrow"></i>
                        </a>
                        <div className="collapse" id="auth">
                            <ul className="nav flex-column sub-menu">
                                <li className="nav-item"> <a className="nav-link" href="pages/samples/login.html"> Login </a></li>
                                <li className="nav-item"> <a className="nav-link" href="pages/samples/login-2.html"> Login 2 </a></li>
                                <li className="nav-item"> <a className="nav-link" href="pages/samples/register.html"> Register </a></li>
                                <li className="nav-item"> <a className="nav-link" href="pages/samples/register-2.html"> Register 2 </a></li>
                                <li className="nav-item"> <a className="nav-link" href="pages/samples/lock-screen.html"> Lockscreen </a></li>
                            </ul>
                        </div>
                    </li> */}
                    <li className="nav-item">
                        <a className="nav-link" href="docs/documentation.html">
                            <i className="icon-book menu-icon"></i>
                            <span className="menu-title">Documentation</span>
                        </a>
                    </li>
                </ul>
            </nav>
        )

    }
}

export default SideBar;
